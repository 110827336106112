.uploader-form-group {
  position: relative;
  overflow: hidden;
  display: inline-block;
  .uploader-mask {
    input[type="file"] {
      opacity: 0;
      position: absolute;
      left: 0;
      height: 100%;
    }
  }
}
